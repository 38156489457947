import Layout from "app/common/layouts/MainLayout/Layout"
import { BlitzPage, GetServerSideProps } from "blitz"
import { Translation } from "react-i18next"

import WelcomePage from "../components/WelcomePage"

const Dashboard: BlitzPage = () => {
  return (
    <>
      <WelcomePage />
    </>
  )
}

Dashboard.authenticate = true
Dashboard.suppressFirstRenderFlicker = true
Dashboard.getLayout = (page) => (
  <Translation>
    {(t) => (
      <Layout title={t("pageTitles.dashboard")} flexGrow={1} unconstrainWidth>
        {page}
      </Layout>
    )}
  </Translation>
)

export const getServerSideProps: GetServerSideProps = async ({ query }) => {
  if (query.login !== "true") {
    return { props: {} }
  }

  return {
    redirect: {
      destination: "/",
      permanent: false,
    },
  }
}

export default Dashboard
