import { AlertProps } from "@chakra-ui/alert/dist/types/alert"
import { Alert, AlertIcon, Collapse } from "@chakra-ui/react"
import { useRef, useState } from "react"

export function useSuccessMessage(timeout: number = 4000): [string, (newMessage: string) => void] {
  const [successMessage, setSuccessMessage] = useState("")
  const timeoutRef = useRef<NodeJS.Timeout | null>(null)

  const setWithTimeout = (newMessage) => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current)
    }

    setSuccessMessage(newMessage)

    if (newMessage) {
      timeoutRef.current = setTimeout(() => setSuccessMessage(""), timeout)
    }
  }

  return [successMessage, setWithTimeout]
}

interface Props extends AlertProps {
  message: string
}

export function SuccessMessage({ message, ...rest }: Props) {
  return (
    <Collapse in={!!message}>
      <Alert status="success" {...rest}>
        <AlertIcon />
        {message}
      </Alert>
    </Collapse>
  )
}
