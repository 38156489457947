import { List, ListItem, Text } from "@chakra-ui/react"
import React, { FC } from "react"
import { useTranslation } from "react-i18next"

import Legend from "../../common/components/Legend"
import { useUserFlags } from "../../utils/misc/userFlags"

export const SubjectColors = {
  Orange: "orange",
  Green: "green",
  Blue: "blue",
  Red: "red",
  Purple: "purple",
}

type Props = {}

const SubjectColorLegend: FC<Props> = () => {
  const { t } = useTranslation()
  const { IS_STAFF } = useUserFlags()

  if (!IS_STAFF) {
    return null
  }

  return (
    <Legend>
      <List>
        <ListItem>
          <Text color={SubjectColors.Red} as="strong">
            Red
          </Text>{" "}
          &mdash; QC hold
        </ListItem>
        <ListItem>
          <Text color={SubjectColors.Orange} as="strong">
            Orange
          </Text>{" "}
          &mdash; Data QC pending
        </ListItem>
        <ListItem>
          <Text color={SubjectColors.Blue} as="strong">
            Blue
          </Text>{" "}
          &mdash; Approved
        </ListItem>
        <ListItem>
          <Text color={SubjectColors.Purple} as="strong">
            Purple
          </Text>{" "}
          &mdash; Historical
        </ListItem>
        <ListItem>
          <Text color={SubjectColors.Green} as="strong">
            Green
          </Text>{" "}
          &mdash; Updated - Pending
        </ListItem>
      </List>
    </Legend>
  )
}

export default SubjectColorLegend
