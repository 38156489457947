import { Flex } from "@chakra-ui/layout"
import { Box } from "@chakra-ui/react"
// import { SampleEventType, SubjectStatus } from "db"
import { SubjectStatus } from "db"
import { FC } from "react"
import { TFunction, useTranslation } from "react-i18next"

import { SubjectAccess } from "../../../subjects2/queries/getUserSubjects2"

type StatusSummaryProps = {
  access: SubjectAccess
}

export const StatusSummary: FC<StatusSummaryProps> = ({ access }) => {
  const [color, status] = useStatusSummary(access)

  return (
    <Flex alignItems="center" fontWeight="bold">
      <Box bg={color} width="10px" height="10px" borderRadius="10px" me={2} />
      {status}
    </Flex>
  )
}

function useStatusSummary(access: SubjectAccess): [string, string] {
  const { t } = useTranslation()
  const statusSummary = computeStatusSummary(access)
  const color = statusSummaryColors[statusSummary]
  const label = statusSummaryLabels[statusSummary](t)

  return [color, label]
}

function computeStatusSummary({ subject }: SubjectAccess): StatusSummaryType {
  if (subject.status === SubjectStatus.Draft) {
    return StatusSummaryType.Draft
  }

  if (subject.status === SubjectStatus.Declared) {
    return StatusSummaryType.Declared
  }

  // const notSentSamples = Object.values(subject.samples)
  //   .flat()
  //   .filter(
  //     (sample) => !sample.sampleEvents.some((event) => event.type === SampleEventType.SentOut)
  //   )
  // if (notSentSamples.length > 0) {
  //   return StatusSummaryType.SampleNotSent
  // }

  const nobodyHasReport = Object.values(subject.reportCounts).every((x) => x === 0)

  if (nobodyHasReport) {
    return StatusSummaryType.WaitingForReport
  }

  return StatusSummaryType.ReportReady
}

// SampleNotSent reverted for now, as it's not clear what the intended behavior is.
export enum StatusSummaryType {
  Draft,
  Declared,
  // SampleNotSent,
  WaitingForReport,
  ReportReady,
}

const statusSummaryColors: Record<StatusSummaryType, string> = {
  [StatusSummaryType.Draft]: "#979797",
  [StatusSummaryType.Declared]: "#066AFF",
  [StatusSummaryType.WaitingForReport]: "#8145FF",
  [StatusSummaryType.ReportReady]: "#000000",
  // [StatusSummaryType.SampleNotSent]: "#F15500",
}

export const statusSummaryLabels: Record<StatusSummaryType, (t: TFunction) => string> = {
  [StatusSummaryType.Draft]: (t) => t("misc.statusSummary.draft"),
  [StatusSummaryType.Declared]: (t) => t("misc.statusSummary.declared"),
  [StatusSummaryType.WaitingForReport]: (t) => t("misc.statusSummary.waitingForReport"),
  [StatusSummaryType.ReportReady]: (t) => t("misc.statusSummary.reportReady"),
  // [StatusSummaryType.SampleNotSent]: (t) => t("misc.statusSummary.sampleNotSent"),
}
