import { Routes } from "blitz"
import { Subject, SubjectWizardStep } from "db"
import { findKey } from "lodash"

const stepLevels: Record<SubjectWizardStep, number> = {
  [SubjectWizardStep.PersonalInfo]: 1,
  [SubjectWizardStep.FamilyHistory]: 2,
  [SubjectWizardStep.ClinicalInfo]: 3,
  [SubjectWizardStep.PhysicalInfo]: 4,
  [SubjectWizardStep.Summary]: 5,
  [SubjectWizardStep.Completed]: 6,
}

export function wizardStepToRoute(step?: SubjectWizardStep) {
  if (step === SubjectWizardStep.FamilyHistory) {
    return Routes.SubjectFamilyHistory
  }

  if (step === SubjectWizardStep.ClinicalInfo) {
    return Routes.SubjectClinicalInfo
  }

  if (step === SubjectWizardStep.Summary) {
    return Routes.SubjectSummary
  }

  if (step === SubjectWizardStep.Completed) {
    return Routes.SubjectDNATest
  }

  return Routes.SubjectPersonalInfo
}

const getStepAfter = (step: SubjectWizardStep): SubjectWizardStep => {
  if (step === SubjectWizardStep.Completed) {
    return SubjectWizardStep.Completed
  }

  if (step === SubjectWizardStep.ClinicalInfo) {
    return SubjectWizardStep.Summary
  }

  const goalLevel = stepLevels[step] + 1

  return findKey(stepLevels, (level) => level === goalLevel) as SubjectWizardStep
}

export const computeNextStepAfterCompletion = (
  subject: Subject,
  completedStep: SubjectWizardStep
): SubjectWizardStep => {
  const currentStep = subject.wizardStep
  const awardedStep = getStepAfter(completedStep)

  if (stepLevels[currentStep] >= stepLevels[awardedStep]) {
    return currentStep
  }

  return awardedStep
}

/**
 * @returns true if subject has alredy completed this step once
 */
export const hasCompletedStep = (subject: Subject, step: SubjectWizardStep) =>
  stepLevels[subject.wizardStep] > stepLevels[step]

/**
 * @returns true if the subject has reached given step in the wizard already
 */
export const hasReachedStep = (subject: Subject, step: SubjectWizardStep) =>
  stepLevels[subject.wizardStep] >= stepLevels[step]

const wizardStepToLocalizationKey: Record<SubjectWizardStep, string> = {
  [SubjectWizardStep.PersonalInfo]: "labels.personalInfo",
  [SubjectWizardStep.FamilyHistory]: "labels.familyHistory",
  [SubjectWizardStep.ClinicalInfo]: "labels.clinicalInfo",
  [SubjectWizardStep.PhysicalInfo]: "labels.physicalInfo",
  [SubjectWizardStep.Summary]: "labels.summary",
  [SubjectWizardStep.Completed]: "does-not-exist",
}

export const getStepNameLocalizationKey = (step: SubjectWizardStep) =>
  wizardStepToLocalizationKey[step]
