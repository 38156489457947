import { ButtonProps } from "@chakra-ui/button"
import { Button } from "@chakra-ui/react"
import { Routes, useMutation, useRouter } from "blitz"
import { FC } from "react"
import { useTranslation } from "react-i18next"
import { FaUser } from "react-icons/fa"

import createSubjectMutation from "../../subjects/mutations/subject-management/createSubject"
import { useUserFlags } from "../../utils/misc/userFlags"

const RegisterSubjectButton: FC<Partial<ButtonProps>> = (props) => {
  const { t } = useTranslation()
  const [createSubject] = useMutation(createSubjectMutation)
  const router = useRouter()
  const { IS_DISTRIBUTOR } = useUserFlags()

  return (
    <Button
      leftIcon={<FaUser />}
      colorScheme="expertBlue"
      data-cy="button-register-subject"
      onClick={async () => {
        const subject = await createSubject()
        if (IS_DISTRIBUTOR) {
          await router.push(Routes.Subject2({ subjectId: subject.id }))
        } else {
          await router.push(Routes.SubjectPersonalInfo({ subjectId: subject.id }))
        }
      }}
      {...props}
    >
      {t("actions.register")}
    </Button>
  )
}

export default RegisterSubjectButton
