import { Table, Tbody, Td, Th, Thead, Tr } from "@chakra-ui/react"
import { TableCellProps, TableColumnHeaderProps } from "@chakra-ui/table/dist/types/table"
import React, { FC } from "react"
import { useTranslation } from "react-i18next"

const tableColor = "#E4E4E4"
const tableIndentation = "50px"
const borderRadius = "10px"
const tableHeadStyle: Partial<TableColumnHeaderProps> = {
  textTransform: "none",
  fontSize: "sm",
  fontWeight: "normal",
}

const tableCellStyle: Partial<TableCellProps> = {
  borderColor: tableColor,
  borderWidth: "1px",
  borderStyle: "solid",
  borderLeftStyle: "none",
  borderRightStyle: "none",
  p: 5,
}

export const SubjectsTable: FC = ({ children }) => {
  const { t } = useTranslation()

  return (
    <Table
      size="sm"
      variant="unstyled"
      my={8}
      style={{ borderCollapse: "separate", borderSpacing: 0, tableLayout: "fixed" }}
    >
      <Thead>
        <Tr>
          <Th {...tableHeadStyle}>{t("labels.subject")}</Th>
          <Th {...tableHeadStyle}>{t("labels.doctor")}</Th>
          <Th {...tableHeadStyle}>{t("labels.statusSummary")}</Th>
          <Th {...tableHeadStyle}>{t("labels.updatedAt")}</Th>
          <Th {...tableHeadStyle} w={tableIndentation} />
        </Tr>
      </Thead>
      <Tbody verticalAlign="top">{children}</Tbody>
    </Table>
  )
}

export const SubjectsRow: FC = ({ children }) => {
  return (
    <>
      <Tr height={4} />
      <Tr cursor="pointer" mb={1}>
        {children}
      </Tr>
    </>
  )
}

interface SubjectsCellProps extends TableCellProps {
  isLeft?: boolean
  isRight?: boolean
}

export const SubjectsCell: FC<SubjectsCellProps> = ({ isLeft, isRight, children, ...props }) => {
  const leftProps: TableCellProps = isLeft
    ? {
        borderLeftStyle: "solid",
        borderTopLeftRadius: borderRadius,
        borderBottomLeftRadius: borderRadius,
      }
    : {}
  const rightProps: TableCellProps = isRight
    ? {
        borderRightStyle: "solid",
        borderTopRightRadius: borderRadius,
        borderBottomRightRadius: borderRadius,
      }
    : {}

  return (
    <Td {...tableCellStyle} {...leftProps} {...rightProps} {...props}>
      {children}
    </Td>
  )
}
