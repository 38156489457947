import { Flex } from "@chakra-ui/react"

import { useUserFlags } from "../../utils/misc/userFlags"
import RegisterSubjectButton from "./RegisterSubjectButton"

const SubjectButtons = () => {
  const { USER_LOADED } = useUserFlags()

  if (!USER_LOADED) {
    return null
  }

  return (
    <>
      <Flex>
        <RegisterSubjectButton />
      </Flex>
    </>
  )
}

export default SubjectButtons
