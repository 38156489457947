import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogCloseButton,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  MenuItem,
  Text,
  useDisclosure,
  useToast,
} from "@chakra-ui/react"
import { useMutation } from "blitz"
import { Subject } from "db"
import { SubjectStatus } from "db"
import { FC, useRef } from "react"
import { useTranslation } from "react-i18next"

import deleteIndexSubject from "../../../mutations/subject-management/deleteIndexSubject"

type Props = {
  subject: Subject
  refetch: () => Promise<any>
}

const DeleteSubjectMenuItem: FC<Props> = ({ subject, refetch }) => {
  const { t } = useTranslation()
  const { isOpen, onOpen, onClose } = useDisclosure()
  const cancelRef = useRef<HTMLButtonElement>(null)
  const [deleteSubjectMutation] = useMutation(deleteIndexSubject)
  const toast = useToast()

  const handleConfirm = async () => {
    await deleteSubjectMutation({ subjectId: subject.id })
    toast({
      title: t("messages.bodies.subjectDelete"),
      status: "success",
      duration: 9000,
      isClosable: true,
    })
    await refetch()
    onClose()
  }

  if (subject.familyIndexId !== null) {
    return null
  }

  if (subject.status !== SubjectStatus.Draft) {
    return null
  }

  return (
    <>
      <MenuItem
        onClick={(e) => {
          e.stopPropagation()
          onOpen()
        }}
        data-cy={`subject-${subject.id}-delete`}
      >
        <Text color={"red"}>{t("actions.delete")}</Text>
      </MenuItem>
      <AlertDialog
        motionPreset="slideInBottom"
        leastDestructiveRef={cancelRef}
        onClose={onClose}
        isOpen={isOpen}
        isCentered
      >
        <AlertDialogOverlay />

        <AlertDialogContent>
          <AlertDialogHeader>{t("dialogs.headings.deleteSubject")}</AlertDialogHeader>
          <AlertDialogCloseButton />
          <AlertDialogBody>{t("dialogs.bodies.confirm")}</AlertDialogBody>
          <AlertDialogFooter>
            <Button ref={cancelRef} onClick={onClose}>
              {t("labels.no")}
            </Button>
            <Button
              colorScheme="red"
              ml={3}
              onClick={handleConfirm}
              data-cy={`subject-${subject.id}-delete-confirm`}
            >
              {t("labels.yes")}
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </>
  )
}

export default DeleteSubjectMenuItem
