import { SubjectColors } from "app/admin/components/SubjectColorLegend"
import { Doctor, QCStatus, Subject } from "db"
import { TFunction } from "react-i18next"

import { SafeUser } from "../../security/types/SafeUser"

/**
 * Returns the color associated with a subject based on its QC status and hold reason.
 * The color is used to display the subject's name in the UI.
 * @param subject - The subject object.
 * @returns The color associated with the subject.
 */
export const getSubjectNameColor = (subject: Subject) => {
  return !!subject.qcHoldReason
    ? SubjectColors.Red
    : subject.qcStatus === QCStatus.Pending
    ? SubjectColors.Orange
    : subject.qcStatus === QCStatus.Approved
    ? SubjectColors.Blue
    : subject.qcStatus === QCStatus.Updated
    ? SubjectColors.Green
    : SubjectColors.Purple
}

export function getSubjectName(subject: Subject | null, t: TFunction): string {
  if (!subject) {
    return ""
  }

  const name = buildName([subject.firstName, subject.middleName, subject.lastName])

  if (!name) {
    return t("labels.unnamedSubject")
  }

  return name
}

export function getDoctorName(doctor: Doctor, t: TFunction | undefined): string {
  const name = buildName([doctor.title, doctor.firstName, doctor.middleName, doctor.lastName])

  if (!name) {
    if (t) return t("labels.unnamedDoctor")
  }

  return name
}

export function getUserName(user: SafeUser | null, t: TFunction): string {
  if (!user) {
    return ""
  }

  const name = buildName([user.firstName, user.lastName])

  if (!name) {
    return t("labels.unnamedUser")
  }

  return name
}

export const buildName = (constituents: (string | null | undefined)[]): string =>
  constituents
    .map((c) => (c ? c.trim() : ""))
    .filter((c) => !!c)
    .join(" ")
    .trim()

export const boolToText = (bool: boolean | null | undefined): string =>
  bool === null || bool === undefined ? "" : bool ? "Yes" : "No"

export function formatIntegerPrice(price: number | null | undefined): string {
  if (price === null || price === undefined) {
    return ""
  }

  const priceStr = price.toString().padStart(3, "0")

  return priceStr.substring(0, priceStr.length - 2) + "." + priceStr.substring(priceStr.length - 2)
}
