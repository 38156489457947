import { Box, Flex, Heading, HStack, LinkBox, SimpleGrid, Text } from "@chakra-ui/layout"
import {
  Avatar,
  Icon,
  IconButton,
  LinkOverlay,
  Skeleton,
  SkeletonCircle,
  Tag,
} from "@chakra-ui/react"
import getUserSubjects from "app/subjects/queries/getUserSubjectsLegacy"
import { Link as BlitzLink, useQuery } from "blitz"
import { SubjectStatus } from "db"
import { FC } from "react"
import { useTranslation } from "react-i18next"
import { BsThreeDotsVertical } from "react-icons/bs"
import { FaSlideshare } from "react-icons/fa"

import { calculateAge } from "../../../utils/misc/dateUtils"
import { getGenderOptions } from "../../constants"
import { wizardStepToRoute } from "../../utils/wizardStepUtils"
import { SubjectActionsMenu } from "../subjectMenu/SubjectActionsMenu"

const SubjectsListTiles: FC = () => {
  const { t } = useTranslation()
  const [subjects, { refetch }] = useQuery(getUserSubjects, {})

  if (subjects.length === 0) {
    return <Text data-cy="subjects-empty-state">{t("messages.titles.subjectsListEmpty")}</Text>
  }

  return (
    <SimpleGrid columns={[1, 1, 2]} spacing={4}>
      {subjects.map((access) => {
        const { id, firstName, lastName, status, birthDateString, gender, wizardStep } =
          access.subject
        const name = firstName && lastName ? `${firstName} ${lastName}` : undefined
        const age = birthDateString ? calculateAge(birthDateString) : null

        return (
          <BlitzLink href={wizardStepToRoute(wizardStep)({ subjectId: id })} key={id} passHref>
            <LinkBox
              data-cy={`subject-${firstName}-${lastName}`}
              data-cy-status={status}
              background="gray.100"
              p={4}
              borderRadius="lg"
              display="flex"
              flexDirection="column"
              justifyContent="space-between"
              _hover={{ textDecoration: "none", boxShadow: "md" }}
              cursor="pointer"
            >
              <Flex alignItems="flex-start" justifyContent="space-between">
                <HStack spacing={4} alignItems="center" w={"full"}>
                  <Avatar size="sm" name={name} background="gray.300" />
                  <Box d={"flex"} flexDir={"column"}>
                    {name && <Heading size="md">{name}</Heading>}
                    <Box d={"flex"} fontSize={"sm"}>
                      {gender && (
                        <Text mr={4}>
                          {getGenderOptions(t).find(({ value }) => value === gender)?.label}
                        </Text>
                      )}
                      {age && <Text>{t("labels.age", { age })}</Text>}
                    </Box>
                  </Box>
                </HStack>
                <Box ml={2}>
                  <LinkOverlay>
                    {access.owned && (
                      <SubjectActionsMenu
                        subject={access.subject}
                        shares={access.shares}
                        refetch={refetch}
                      />
                    )}
                  </LinkOverlay>
                </Box>
              </Flex>
              <Flex alignItems="center" justifyContent="space-between" mt={6}>
                <Tag
                  colorScheme={status === SubjectStatus.Submitted ? "green" : "orange"}
                  ms="auto"
                  fontWeight="bold"
                  textTransform="uppercase"
                >
                  {t(`labels.${status.toLowerCase()}`)}
                </Tag>
                {!access.owned ? (
                  <Box ml={2}>
                    <FaSlideshare />
                  </Box>
                ) : null}
              </Flex>
            </LinkBox>
          </BlitzLink>
        )
      })}
    </SimpleGrid>
  )
}

export default SubjectsListTiles

export const SubjectsListLoader: FC = () => {
  return (
    <SimpleGrid columns={2}>
      <Box
        background="gray.100"
        p={4}
        borderRadius="lg"
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
      >
        <Flex alignItems="flex-start" justifyContent="space-between">
          <HStack spacing={4}>
            <SkeletonCircle size="32px" />
            <Box>
              <Skeleton height="20px" width="100px" mb={2} />
              <Skeleton height="14px" width="30px" />
            </Box>
          </HStack>
          <IconButton size="sm" aria-label="more" isRound isDisabled>
            <Icon as={BsThreeDotsVertical} />
          </IconButton>
        </Flex>
        <Flex alignItems="flex-end" justifyContent="space-between" mt={6}>
          <Skeleton height="14px" width="30px" />
          <Skeleton height="14px" width="90px" />
        </Flex>
      </Box>
    </SimpleGrid>
  )
}
