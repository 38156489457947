import moment from "moment-timezone"

export const calculateAge = (birthDateString: string) =>
  moment().diff(moment(birthDateString), "years")

export const getPortalStyleDate = (
  date: string | Date | null | undefined,
  fallback: string = ""
): string => (date ? moment(date).format("DD MMM YYYY") : fallback)

export const getPortalStyleDateTime = (
  date: string | Date | null | undefined,
  fallback: string = ""
): string => (date ? moment(date).format("DD MMM YYYY, HH:mm:ss") : fallback)

export const getPortalStyleMonth = (
  date: string | Date | null | undefined,
  fallback: string = ""
): string => (date ? moment(date).format("MMM YYYY") : fallback)

export const getGermanStyleDate = (
  date: string | Date | null | undefined,
  fallback: string = ""
): string => (date ? moment(date).format("DD.MM.YYYY") : fallback)

export const asIso8601Date = (date: Date): string => {
  const momentDate = moment(date)
  const isoString = momentDate?.format("YYYY-MM-DD")

  if (!momentDate || !momentDate.isValid() || !isoString) {
    throw "Error with asIso8601Date conversion"
  }

  return isoString
}

export const asIso8601Datetime = (date: Date | null | undefined): string | null => {
  if (!date) {
    return null
  }

  const momentDate = moment(date)
  const isoString = momentDate?.toISOString(true)

  if (!momentDate || !momentDate.isValid() || !isoString) {
    throw "Error with asIso8601Date conversion"
  }

  return isoString
}

export const asIso8601DatetimeGermanTZ = (date: Date | null | undefined): string | null => {
  if (!date) {
    return null
  }

  const momentDate = moment(date)?.tz("Europe/Berlin")
  const isoString = momentDate?.toISOString(true)

  if (!momentDate || !momentDate.isValid() || !isoString) {
    throw "Error with asIso8601Date conversion"
  }

  return isoString
}
