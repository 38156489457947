import { Flex, Grid, Input, InputGroup, Select } from "@chakra-ui/react"
import React, { FC, useEffect } from "react"
import { useTranslation } from "react-i18next"

import { useDebouncedState } from "../../../utils/misc/debounce"
import { getEnumValues } from "../../../utils/misc/getEnumValues"
import { statusSummaryLabels, StatusSummaryType } from "./StatusSummary"

type Props = {
  setSearch: (x: string) => void
  statusSummary: StatusSummaryType | undefined
  setStatusSummary: (x: StatusSummaryType | undefined) => void
}

const SubjectsToolbar: FC<Props> = ({
  setSearch: updateParentSearch,
  statusSummary,
  setStatusSummary,
}) => {
  const { t } = useTranslation()
  const [search, slowSearch, updateSearch] = useDebouncedState("")

  useEffect(() => updateParentSearch(slowSearch), [slowSearch, updateParentSearch])

  return (
    <Grid templateColumns="auto 1fr auto 1fr" gap={4} mb={12}>
      <Flex alignItems="center">{t("labels.search")}:</Flex>
      <Input value={search} onChange={(e) => updateSearch(e.target.value)} />
      <Flex alignItems="center">{t("labels.statusFilter")}:</Flex>
      <InputGroup>
        <Select
          bg="white"
          value={statusSummary?.toString() ?? ""}
          onChange={(e) => {
            setStatusSummary(e.target.value ? Number(e.target.value) : undefined)
          }}
          data-cy="select-statusSummary"
        >
          <option value={""}>{t("labels.allStatuses")}</option>
          {getEnumValues<StatusSummaryType>(StatusSummaryType).map((status) => (
            <option key={status} value={status}>
              {statusSummaryLabels[status](t)}
            </option>
          ))}
        </Select>
      </InputGroup>
    </Grid>
  )
}

export default SubjectsToolbar
