import moment from "moment"
import * as z from "zod"

const safeStringBase = z.string().max(512)
export const safeString = safeStringBase.nonempty()
export const optionalSafeString = safeStringBase.optional().nullable() // Empty, undefined and null are all okay

const longSafeStringBase = z.string().max(2000)
export const longSafeString = longSafeStringBase.nonempty()
export const longOptionalSafeString = longSafeString.optional().nullable() // Empty, undefined and null are all okay

export const safeEmail = safeString.and(z.string().email())
export const optionalSafeEmail = optionalSafeString
  .and(z.string().email().or(z.literal("")))
  .optional()
  .nullable()

const phoneBase = z
  .string()
  .regex(/^\+?(\s*\d\s*){4,}$/g, 'Must only contain numbers (or "+" at the beginning)')
export const safePhone = safeString.and(phoneBase)
export const optionalSafePhone = optionalSafeString
  .and(phoneBase.or(z.literal("")))
  .optional()
  .nullable()

const iso8601DateRefine = <T>(x: z.ZodType<T>) =>
  x.refine((str) => {
    const date = moment(str, "YYYY-MM-DD")
    return !!date && date.isValid()
  }, "Must be a valid date")
export const safeIso8601Date = iso8601DateRefine(safeString)
export const optionalSafeIso8601Date = iso8601DateRefine(optionalSafeString).optional().nullable()

const iso8601DateTimeRefine = <T>(x: z.ZodType<T>) =>
  x.refine((str) => {
    const date = moment(str, moment.ISO_8601)
    return !!date && date.isValid()
  }, "Must be a valid date")
export const safeIso8601DateTime = iso8601DateTimeRefine(safeString)
export const optionalSafeIso8601DateTime = iso8601DateTimeRefine(optionalSafeString)
  .optional()
  .nullable()

export const booleanString = z.union([z.literal("true"), z.literal("false")])
