import { useCallback, useMemo, useState } from "react"

export function debounce(func: Function, timeout = 300): Function {
  let timer
  return (...args) => {
    clearTimeout(timer)
    timer = setTimeout(() => {
      func.apply(this, args)
    }, timeout)
  }
}

export function useDebouncedState<T>(
  defaultValue: T,
  timeout?: number
): [T, T, (newValue: T) => void] {
  const [value, setValue] = useState(defaultValue)
  const [slowValue, setSlowValue] = useState(defaultValue)

  const debouncedSetSlowValue = useMemo(
    () => debounce((newValue: T) => setSlowValue(newValue), timeout),
    [setSlowValue, timeout]
  )

  const update = useCallback(
    (newValue: T) => {
      setValue(newValue)
      debouncedSetSlowValue(newValue)
    },
    [setValue, debouncedSetSlowValue]
  )

  return [value, slowValue, update]
}
