import { Box } from "@chakra-ui/layout"
import { Divider, Flex, Heading } from "@chakra-ui/react"
import { Suspense, useState } from "react"
import { useTranslation } from "react-i18next"

import SubjectButtons from "../SubjectButtons"
import { DistributorSubjects, DistributorSubjectsLoader } from "./DistributorSubjects"
import { StatusSummaryType } from "./StatusSummary"
import SubjectsToolbar from "./SubjectsToolbar"

const WelcomePageDistributors = () => {
  const { t } = useTranslation()
  const [search, setSearch] = useState("")
  const [statusSummary, setStatusSummary] = useState<StatusSummaryType | undefined>(undefined)

  return (
    <>
      <Flex alignItems="flex-end">
        <Heading as="h1" size="md" data-cy="heading-welcome-distributor">
          {t("misc.dashboard.greetingYourSubjects")}
        </Heading>

        <Box ms="auto">
          <SubjectButtons />
        </Box>
      </Flex>

      <Box h={8} />

      <Divider pos="absolute" left={0} />

      <Box h={4} />

      <SubjectsToolbar
        setSearch={setSearch}
        statusSummary={statusSummary}
        setStatusSummary={setStatusSummary}
      />

      <Suspense fallback={<DistributorSubjectsLoader />}>
        <DistributorSubjects search={search} statusSummary={statusSummary} />
      </Suspense>
    </>
  )
}

export default WelcomePageDistributors
