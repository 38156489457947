import {
  Input,
  InputGroup,
  InputLeftElement,
  InputProps,
  InputRightElement,
} from "@chakra-ui/react"
import { forwardRef } from "react"
import { useField } from "react-final-form"
import { useTranslation } from "react-i18next"

import Field, { FieldProps } from "./Field"

export interface TextFieldProps extends FieldProps, Omit<InputProps, "ref" | "name"> {
  type?: "text" | "password" | "email" | "number" | "hidden"
  placeholder?: string
  inputLeftElement?: React.ReactNode
  inputRightElement?: React.ReactNode
}

const TextField = forwardRef<HTMLInputElement, TextFieldProps>((props, ref) => {
  const {
    helperText,
    isRequired,
    isReadOnly,
    outerProps,
    errorAsTooltip,
    inputLeftElement,
    inputRightElement,
    labelSibling,
    ...inputProps
  } = props
  const { t } = useTranslation()
  const { input } = useField(props.name, {
    parse: props.type === "number" ? (v) => (v ? Number(v) : null) : (v) => v,
  })

  const pattern = isRequired ? ".*\\S+.*" : undefined
  const title = isRequired ? t("labels.field_required") : undefined

  return (
    <Field {...props}>
      <InputGroup>
        {inputLeftElement && (
          <InputLeftElement _rtl={{ left: "auto", right: 0 }}>{inputLeftElement}</InputLeftElement>
        )}
        <Input {...input} {...inputProps} ref={ref} pattern={pattern} title={title} />
        {inputRightElement && (
          <InputRightElement _rtl={{ left: 0, right: "auto" }}>
            {inputRightElement}
          </InputRightElement>
        )}
      </InputGroup>
    </Field>
  )
})

export default TextField
