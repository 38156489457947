import { AlertDescription } from "@chakra-ui/alert"
import { AddIcon, CheckIcon, CloseIcon } from "@chakra-ui/icons"
import { Heading } from "@chakra-ui/layout"
import {
  Alert,
  AlertIcon,
  Box,
  Button,
  Center,
  IconButton,
  Link,
  ListItem,
  MenuItem,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Table,
  Tbody,
  Td,
  Text,
  Tr,
  UnorderedList,
  useDisclosure,
} from "@chakra-ui/react"
import { useMutation } from "blitz"
import { AccessType, Subject, SubjectShare } from "db"
import { FC, useState } from "react"
import { Trans, useTranslation } from "react-i18next"
import { FaBan } from "react-icons/fa"
import { FaSlideshare } from "react-icons/fa"

import CheckboxField from "../../../../common/components/CheckboxField"
import Form, { FORM_ERROR } from "../../../../common/components/Form"
import { SuccessMessage, useSuccessMessage } from "../../../../common/components/SuccessMessage"
import TextField from "../../../../common/components/TextField"
import { SafeUser } from "../../../../security/types/SafeUser"
import { ShareEmailTakenError } from "../../../../utils/errorHandling/backendErrors"
import { getPortalStyleDate } from "../../../../utils/misc/dateUtils"
import { getSubjectName } from "../../../../utils/misc/smallUtils"
import revokeSubjectShare from "../../../mutations/sharing/revokeSubjectShare"
import shareSubject from "../../../mutations/sharing/shareSubject"
import { ShareSubject } from "../../../validations"

type Props = {
  shares: (SubjectShare & { user: SafeUser })[]
  refetch: () => Promise<any>
  subject: Subject
}

const ShareSubjectMenuItem: FC<Props> = ({ shares, refetch, subject }) => {
  const { t } = useTranslation()
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [revokeShareMutation] = useMutation(revokeSubjectShare)
  const [forceShowAddShare, setForceShowAddShare] = useState(false)
  const [successMessage, setSuccessMessage] = useSuccessMessage()

  return (
    <>
      <MenuItem
        onClick={(e) => {
          e.stopPropagation()
          onOpen()
        }}
        data-cy={`subject-${subject.id}-share-modal`}
      >
        {t("actions.shareSubject")}
      </MenuItem>
      <Modal isOpen={isOpen} onClose={onClose} size="xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            {t("dialogs.headings.shareSubject", {
              name: getSubjectName(subject, t),
            })}
          </ModalHeader>
          <ModalCloseButton _rtl={{ left: 3, right: "auto" }} />
          <ModalBody>
            <SuccessMessage message={successMessage} mb={4} />
            {shares.length > 0 ? (
              <>
                <Text>{t("dialogs.bodies.subjectShared")}</Text>
                <Table>
                  <Tbody>
                    {shares.map((share) => (
                      <SingleShareRow
                        key={share.id}
                        share={share}
                        onRevoke={async () => {
                          await revokeShareMutation({ id: subject.id, userId: share.user.id })
                          await refetch()
                          setSuccessMessage(t("messages.titles.shareRevoke"))
                        }}
                      />
                    ))}
                  </Tbody>
                </Table>
              </>
            ) : (
              <Text>{t("dialogs.bodies.subjectNotShared")}</Text>
            )}

            {shares.length === 0 || forceShowAddShare ? (
              <>
                <Heading as="h5" size="sm" mt={4} mb={2}>
                  {t("dialogs.headings.addShare")}
                </Heading>

                <Text as="div">
                  <Trans
                    t={t}
                    i18nKey="dialogs.bodies.addShare"
                    components={{
                      ul: <UnorderedList />,
                      li: <ListItem />,
                    }}
                  />
                </Text>

                <AddShareForm
                  subject={subject}
                  onShare={async () => {
                    await refetch()
                    setForceShowAddShare(false)
                    setSuccessMessage(t("messages.titles.newShare"))
                  }}
                />
              </>
            ) : (
              <>
                <Button
                  ms="auto"
                  me="auto"
                  w="100%"
                  leftIcon={<AddIcon />}
                  onClick={() => setForceShowAddShare(true)}
                  mt={4}
                >
                  {t("actions.shareWithAnother")}
                </Button>
              </>
            )}
          </ModalBody>

          <ModalFooter alignItems="center">
            <Button variant="ghost" onClick={onClose}>
              {t("actions.close")}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}

type SingleShareRowProps = {
  share: SubjectShare & { user: SafeUser }
  onRevoke: () => void
}

function SingleShareRow({ share, onRevoke }: SingleShareRowProps) {
  const { t } = useTranslation()
  const [showRevoke, setShowRevoke] = useState(false)

  return (
    <Tr>
      <Td data-cy={`sharing-info-${share.user.email}`}>
        <strong>{share.user.email}</strong>
        <br />
        {share.user.firstName} {share.user.lastName}
        <br />
        <Text color="gray.500">
          {t("labels.sharedOn")}: {getPortalStyleDate(share.createdAt)}
          <br />
          {share.accessType === AccessType.ReadWrite
            ? t("labels.viewAndEdit")
            : t("labels.viewOnly")}
        </Text>
      </Td>
      <Td minWidth={305}>
        <Center>
          {showRevoke ? (
            <Center>
              <Text>{t("messages.bodies.confirmShareRevoke")}</Text>

              <IconButton
                onClick={() => setShowRevoke(false)}
                icon={<CloseIcon />}
                aria-label="Abort"
                ml={1}
                isRound
              />
              <IconButton
                onClick={onRevoke}
                icon={<CheckIcon />}
                aria-label="Confirm"
                colorScheme="red"
                ml={1}
                data-cy={`confirm-revoke-sharing-${share.user.email}`}
                isRound
              />
            </Center>
          ) : (
            <Link
              display="flex"
              alignItems="center"
              color="red"
              data-cy={`revoke-sharing-${share.user.email}`}
              onClick={() => setShowRevoke(true)}
            >
              <Box mr="0.5rem">
                <FaBan />
              </Box>
              {t("actions.revokeSharing")}
            </Link>
          )}
        </Center>
      </Td>
    </Tr>
  )
}

type AddShareFormProps = {
  onShare: () => Promise<void>
  subject: Subject
}

function AddShareForm({ onShare, subject }: AddShareFormProps) {
  const { t } = useTranslation()
  const [shareSubjectMutation] = useMutation(shareSubject)
  const [inviteEmail, setInviteEmail] = useState<string | null>(null)

  return (
    <Form
      schema={ShareSubject}
      initialValues={{ id: subject.id, allowEditing: false }}
      onSubmit={async (values, form) => {
        try {
          await shareSubjectMutation({ ...values, allowInvite: !!inviteEmail })
          await onShare()
          form.restart()
          setInviteEmail(null)
        } catch (error) {
          if (error instanceof ShareEmailTakenError) {
            setInviteEmail(null)
            return {
              email: t("errors.shareEmailTaken"),
            }
          } else if (error.statusCode === 404) {
            setInviteEmail(values.email)
            return
          } else {
            setInviteEmail(null)
            return {
              [FORM_ERROR]: error.toString(),
            }
          }
        }
      }}
    >
      {({ submitting }) => (
        <>
          {inviteEmail ? (
            <Alert status="warning" variant="subtle" flexDirection="column" mt={4}>
              <Center>
                <AlertIcon />
                <Text>
                  <Trans
                    t={t}
                    i18nKey="messages.bodies.confirmInviteUser"
                    components={{
                      em: <em />,
                    }}
                    values={{
                      email: inviteEmail,
                    }}
                  />
                </Text>
              </Center>

              <AlertDescription>
                <Box mt={2}>
                  <Button disabled={submitting} onClick={() => setInviteEmail(null)}>
                    {t("actions.cancel")}
                  </Button>
                  <Button
                    type="submit"
                    colorScheme="expertBlue"
                    isLoading={submitting}
                    leftIcon={<FaSlideshare />}
                    ms={2}
                    data-cy="invite-submit-button"
                  >
                    {t("actions.inviteAndShare")}
                  </Button>
                </Box>
              </AlertDescription>
            </Alert>
          ) : (
            <>
              <TextField
                type="email"
                name="email"
                label={t("labels.email")}
                placeholder={t("placeholders.email")}
                outerProps={{ mt: 4 }}
                isRequired
              />

              <CheckboxField name="allowEditing" mt={4} data-cy="allow-editing-checkbox">
                {t("labels.allowShareEditing")}
              </CheckboxField>

              <Box mt={4}>
                <Button
                  ms="auto"
                  me="auto"
                  type="submit"
                  colorScheme="expertBlue"
                  isLoading={submitting}
                  data-cy="share-submit-button"
                  w="100%"
                  leftIcon={<FaSlideshare />}
                >
                  {t("actions.shareSubject")}
                </Button>
                <Text mt={2} color="gray.500">
                  {t("dialogs.bodies.shareRevokeInfo")}
                </Text>
              </Box>
            </>
          )}
        </>
      )}
    </Form>
  )
}

export default ShareSubjectMenuItem
