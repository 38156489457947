import { Icon, IconButton, Menu, MenuButton, MenuList } from "@chakra-ui/react"
import { Subject, SubjectShare } from "db"
import React, { FC } from "react"
import { BsThreeDotsVertical } from "react-icons/bs"

import { SafeUser } from "../../../security/types/SafeUser"
import DeleteSubjectMenuItem from "./menuItems/DeleteSubjectMenuItem"
import ShareSubjectMenuItem from "./menuItems/ShareSubjectMenuItem"

type Props = {
  subject: Subject
  shares: (SubjectShare & { user: SafeUser })[]
  refetch: () => Promise<any>
}

export const SubjectActionsMenu: FC<Props> = ({ subject, shares, refetch }) => {
  return (
    <>
      <Menu placement="bottom-end">
        <MenuButton
          as={IconButton}
          size="sm"
          aria-label="more"
          isRound
          onClick={(e) => e.stopPropagation()}
          data-cy={`subject-${subject.id}-menu`}
        >
          <Icon as={BsThreeDotsVertical} />
        </MenuButton>
        <MenuList zIndex={5}>
          <ShareSubjectMenuItem shares={shares} subject={subject} refetch={refetch} />
          <DeleteSubjectMenuItem subject={subject} refetch={refetch} />
        </MenuList>
      </Menu>
    </>
  )
}
