import { Box } from "@chakra-ui/layout"
import { Heading } from "@chakra-ui/react"
import { useTranslation } from "react-i18next"

import SubjectsListTiles from "../../subjects/components/misc/SubjectsListTiles"
import SubjectButtons from "./SubjectButtons"

const WelcomePageStandard = () => {
  const { t } = useTranslation()

  return (
    <>
      <Heading as="h1" size="md" mb={4}>
        {t("misc.dashboard.greetingYourSubjects")}
      </Heading>

      <Box>
        <SubjectButtons />
      </Box>

      <SubjectsListTiles />
    </>
  )
}

export default WelcomePageStandard
