import { FamilyRelation, Gender, UserRelationToSubject, YesNoUnknown } from "db"
import moment from "moment"
import { TFunction } from "react-i18next"
import * as z from "zod"
import { RefinementCtx } from "zod"

import {
  booleanString,
  optionalSafeEmail,
  optionalSafeIso8601Date,
  optionalSafeString,
  safeIso8601Date,
  safeString,
} from "../security/validation/templates"

export const getPersonalInfoSchema = () => {
  return z.object({
    id: safeString,
    email: optionalSafeEmail,
    userRelation: z.nativeEnum(UserRelationToSubject),
    firstName: safeString,
    lastName: safeString,
    race: optionalSafeString,
    ethnicity: optionalSafeString,
    gender: z.nativeEnum(Gender),
    birthDateString: safeIso8601Date,
    addressLine1: optionalSafeString,
    addressHouseNumber: optionalSafeString,
    addressLine2: optionalSafeString,
    addressCity: optionalSafeString,
    addressPostCode: optionalSafeString,
    addressCountry: optionalSafeString,
    height: z.number().int().positive().max(250),
    weight: z.number().int().positive().max(400),
  })
}

export const PersonalInfo = getPersonalInfoSchema()

const optionalFamilyRelation = z.nativeEnum(FamilyRelation).optional().nullable()

export const FamilyHistory = z.object({
  id: safeString,
  consanguinity: z.nativeEnum(YesNoUnknown),

  cancer: z.nativeEnum(YesNoUnknown),
  cancerFamilyRelation: optionalFamilyRelation,

  miscarriage: z.nativeEnum(YesNoUnknown),
  miscarriageFamilyRelation: optionalFamilyRelation,

  longTermTreatment: z.nativeEnum(YesNoUnknown),
  longTermTreatmentFamilyRelation: optionalFamilyRelation,

  mentalHealthIssues: z.nativeEnum(YesNoUnknown),
  mentalHealthIssuesFamilyRelation: optionalFamilyRelation,

  heartProblems: z.nativeEnum(YesNoUnknown),
  heartProblemsFamilyRelation: optionalFamilyRelation,

  visionLoss: z.nativeEnum(YesNoUnknown),
  visionLossFamilyRelation: optionalFamilyRelation,

  hearingLoss: z.nativeEnum(YesNoUnknown),
  hearingLossFamilyRelation: optionalFamilyRelation,

  stroke: z.nativeEnum(YesNoUnknown),
  strokeFamilyRelation: optionalFamilyRelation,
})

export const isDateAfterBirthDate = (dateString?: string | null, birthDateString?: string | null) =>
  !dateString ||
  !birthDateString ||
  dateString === "1900-01-01" ||
  moment(dateString).isSameOrAfter(moment(birthDateString))

export const isEndDateAfterBeginDate = (
  beginDateString?: string | null,
  endDateString?: string | null
) => !beginDateString || !endDateString || moment(beginDateString).isBefore(moment(endDateString))

const SymptomBaseSchema = z.object({
  beginDateString: optionalSafeIso8601Date,
  endDateString: optionalSafeIso8601Date,
})

function symptomDateRefinement(
  formData: { beginDateString?: string | null; endDateString?: string | null },
  ctx: RefinementCtx,
  t: TFunction = () => {},
  birthDateString?: string | null
) {
  if (!isDateAfterBirthDate(formData.beginDateString, birthDateString)) {
    ctx.addIssue({
      code: z.ZodIssueCode.custom,
      message: t("errors.symptomBeginDate"),
      path: ["beginDateString"],
    })
  }

  if (!isDateAfterBirthDate(formData.endDateString, birthDateString)) {
    ctx.addIssue({
      code: z.ZodIssueCode.custom,
      message: t("errors.symptomEndDateBirthDate"),
      path: ["endDateString"],
    })
  }

  if (!isEndDateAfterBeginDate(formData.beginDateString, formData.endDateString)) {
    ctx.addIssue({
      code: z.ZodIssueCode.custom,
      message: t("errors.symptomEndDate"),
      path: ["endDateString"],
    })
  }
}

export const getSubjectSymptomSchema = (t: TFunction = () => {}, birthDateString?: string) => {
  return SymptomBaseSchema.extend({
    symptomKey: safeString,
  }).superRefine((formData, ctx) => symptomDateRefinement(formData, ctx, t, birthDateString))
}

export const getSubjectHpoTermSchema = (t: TFunction = () => {}, birthDateString?: string) => {
  return SymptomBaseSchema.extend({
    hpoId: safeString,
  }).superRefine((formData, ctx) => symptomDateRefinement(formData, ctx, t, birthDateString))
}

export const getClinicalInfoSchema = (t: TFunction = () => {}, birthDateString?: string) => {
  return z.object({
    id: safeString,
    symptoms: getSubjectSymptomSchema(t, birthDateString).array(),
    hpoTerms: getSubjectHpoTermSchema(t, birthDateString).array().optional(),
    comments: z.string().max(5000).optional().nullable(),
  })
}

export const ClinicalInfo = getClinicalInfoSchema()

const sampleCollectionDateString = (t: TFunction = () => {}, checkDate = false) => ({
  sampleCollectionDateString: safeIso8601Date.refine(
    (dateString) =>
      !checkDate || moment(dateString).isSameOrBefore(moment().add(30, "d").endOf("day").toDate()),
    t("errors.sampleCollectionDateString")
  ),
})

export const getSampleKitInformationSchema = (t: TFunction = () => {}) => {
  return z.object({
    id: safeString,
    deviceId: safeString,
    ...sampleCollectionDateString(t),
  })
}

export const SampleKitInformation = getSampleKitInformationSchema()

export const OnSubmit = z.object({ onSubmit: z.function().optional() })

export const SubjectDeclaration = z.object({
  id: safeString,
  formConsent: z.boolean(),
  researchConsent: booleanString,
  incidentalFindingsConsent: booleanString,
  photoVideoConsent: booleanString,
})

const SamplePickupBase = z.object({
  id: safeString,
  name: safeString,
  addressLine1: safeString,
  addressHouseNumber: optionalSafeString,
  addressLine2: optionalSafeString,
  addressCity: safeString,
  addressPostCode: safeString,
  addressCountry: safeString,
  phone: optionalSafeString,
})

export const SamplePickupFrontend = SamplePickupBase.extend({
  preferredPickupDateString: safeIso8601Date,
  preferredPickupTimeOfDay: safeString,
})

export const SamplePickupBackend = SamplePickupBase.extend({
  preferredPickupTime: safeString,
})

export const ShareSubject = z.object({
  id: safeString,
  email: safeString.email(),
  allowEditing: z.boolean(),
  allowInvite: z.boolean().optional(),
})

export const RevokeSubjectShare = z.object({
  id: safeString,
  userId: safeString,
})
