import { ChevronLeftIcon, ChevronRightIcon } from "@chakra-ui/icons"
import { Button, Divider, Flex, Grid, GridProps } from "@chakra-ui/react"
import React, { FC } from "react"
import { useTranslation } from "react-i18next"

interface Props extends GridProps {
  hasMore: boolean
  page: number
  switchPage: (newPage: number) => void
  count?: number
  pageSize?: number
}

const TableNavigation: FC<Props> = ({ hasMore, page, switchPage, count, pageSize, ...rest }) => {
  const { t } = useTranslation()
  const goToPreviousPage = () => switchPage(page - 1)
  const goToNextPage = () => switchPage(page + 1)
  const countText = getCountText(count, pageSize)

  return (
    <Grid
      templateColumns="max(10%, 150px) 1fr auto 1fr max(10%, 150px)"
      gap={8}
      alignItems="center"
      {...rest}
    >
      <Button
        disabled={page === 0}
        onClick={goToPreviousPage}
        leftIcon={<ChevronLeftIcon />}
        size="sm"
      >
        {t("actions.previous")}
      </Button>
      <Divider borderBottomWidth={2} />
      <Flex alignItems="center" justifyContent="center">
        <em>
          Page {page + 1}
          {countText}
        </em>
      </Flex>
      <Divider borderBottomWidth={2} />
      <Button disabled={!hasMore} onClick={goToNextPage} rightIcon={<ChevronRightIcon />} size="sm">
        {t("actions.next")}
      </Button>
    </Grid>
  )
}

function getCountText(count: number | undefined, pageSize: number | undefined): string {
  if (count === undefined || pageSize === undefined) {
    return ""
  }

  const amountPage = Math.max(Math.ceil(count / Math.max(pageSize, 1)), 1)

  return ` of ${amountPage} — ${count} items`
}

export default TableNavigation
