import { Button, Flex, Grid, Heading } from "@chakra-ui/react"
import { Link, Routes, RouteUrlObject } from "blitz"
import React from "react"
import { useTranslation } from "react-i18next"
import { FaMoneyBill, FaPlusSquare, FaSearch, FaUser, FaWaveSquare } from "react-icons/fa"

import { useAuthContext } from "../../utils/misc/AuthContext"
import { UserFlag, useUserFlags } from "../../utils/misc/userFlags"

type AdminButton = {
  key: string
  url: RouteUrlObject
  icon: JSX.Element
  dataCy: string
  authorize: (userFlags: Record<UserFlag, boolean>) => boolean
}

const adminButtons: AdminButton[] = [
  {
    key: "actions.subjectOverview",
    url: Routes.SubjectsAdminPage(),
    icon: <FaWaveSquare />,
    dataCy: "",
    authorize: () => true,
  },
  {
    key: "actions.assignedSamples",
    url: Routes.AssignedSamplesPage(),
    icon: <FaSearch />,
    dataCy: "",
    authorize: () => true,
  },
  {
    key: "actions.userOverview",
    url: Routes.UsersAdminPage(),
    icon: <FaUser />,
    dataCy: "",
    authorize: ({ IS_EDITOR }) => IS_EDITOR,
  },
  {
    key: "actions.sampleRegistration",
    url: Routes.SampleRegistrationPage(),
    icon: <FaPlusSquare />,
    dataCy: "button-sampleRegistration",
    authorize: ({ IS_EDITOR }) => IS_EDITOR,
  },
  {
    key: "actions.finance",
    url: Routes.FinancePage(),
    icon: <FaMoneyBill />,
    dataCy: "",
    authorize: ({ IS_EDITOR }) => IS_EDITOR,
  },
]

const WelcomePageStaff = () => {
  const { t } = useTranslation()
  const { currentUser } = useAuthContext()
  const userFlags = useUserFlags()

  return (
    <Flex alignItems="center" justifyContent="center" flexDirection="column">
      <Heading size="lg" mb={8}>
        Welcome to the staff area, {currentUser!.firstName}!
      </Heading>

      <Grid templateColumns="repeat(2, 1fr)" gap={4} w={500}>
        {adminButtons.map(({ icon, key, url, dataCy, authorize }) => {
          if (!authorize(userFlags)) {
            return null
          }

          return (
            <React.Fragment key={key}>
              <Link href={url} passHref>
                <Button
                  key={key}
                  flexDirection="column"
                  colorScheme="expertBlue"
                  variant="outline"
                  height="242px"
                  fontSize="lg"
                  data-cy={dataCy}
                >
                  {icon}
                  {t(key)}
                </Button>
              </Link>
            </React.Fragment>
          )
        })}
      </Grid>
    </Flex>
  )
}

export default WelcomePageStaff
