import { InfoOutlineIcon } from "@chakra-ui/icons"
import { Tooltip } from "@chakra-ui/react"
import React, { FC } from "react"
import { useTranslation } from "react-i18next"

type Props = {}

const Legend: FC<Props> = ({ children }) => {
  const { t } = useTranslation()

  return (
    <Tooltip label={children} bg="white" color="black">
      <InfoOutlineIcon ms={2} />
    </Tooltip>
  )
}

export default Legend
