import { Text } from "@chakra-ui/layout"
import { Skeleton, Td, Tr } from "@chakra-ui/react"
import { TableCellProps } from "@chakra-ui/table/dist/types/table"
import { Routes, useQuery, useRouter } from "blitz"
import { FC, Fragment } from "react"
import { useTranslation } from "react-i18next"

import TableNavigation from "../../../common/components/TableNavigation"
import { SubjectActionsMenu } from "../../../subjects/components/subjectMenu/SubjectActionsMenu"
import getUserSubjects2, { SubjectAccess } from "../../../subjects2/queries/getUserSubjects2"
import { getPortalStyleDate, getPortalStyleDateTime } from "../../../utils/misc/dateUtils"
import { getDoctorName, getSubjectName } from "../../../utils/misc/smallUtils"
import { StatusSummary, StatusSummaryType } from "./StatusSummary"
import { SubjectsCell, SubjectsRow, SubjectsTable } from "./SubjectsTable"

const ITEMS_PER_PAGE = 25

type DistributorSubjectsProps = {
  search: string
  statusSummary: StatusSummaryType | undefined
}

export const DistributorSubjects: FC<DistributorSubjectsProps> = ({ search, statusSummary }) => {
  const { t } = useTranslation()
  const router = useRouter()
  const page = Number(router.query.page) || 0
  const [{ subjects, hasMore, count }, { refetch }] = useQuery(getUserSubjects2, {
    skip: ITEMS_PER_PAGE * page,
    take: ITEMS_PER_PAGE,
    search,
    statusSummary,
  })

  return (
    <>
      <TableNavigation
        page={page}
        hasMore={hasMore}
        switchPage={(newPage) => router.push({ query: { page: newPage } })}
        pageSize={ITEMS_PER_PAGE}
        count={count}
      />

      <SubjectsTable>
        {subjects.length === 0 && (
          <Tr>
            <Td colSpan={5} color="gray.500" pt={8}>
              No matching subjects found.
            </Td>
          </Tr>
        )}
        {subjects.map((access: SubjectAccess) => {
          const { subject } = access
          const actions: Partial<TableCellProps> = {
            onClick: async () => await router.push(Routes.Subject2({ subjectId: subject.id })),
          }

          return (
            <SubjectsRow key={subject.id}>
              <SubjectsCell {...actions} isLeft>
                <Text fontSize="md" fontWeight="bold" mb={2}>
                  {getSubjectName(subject, t)}
                </Text>
                {[subject.gender, getPortalStyleDate(subject.birthDateString)]
                  .filter((x) => !!x)
                  .join(" | ")}
              </SubjectsCell>
              <SubjectsCell {...actions}>
                <Text fontWeight="bold" mb={2}>
                  {subject["doctor"] ? getDoctorName(subject["doctor"], t) : null}
                </Text>
                <Text>{subject["doctor"]?.email}</Text>
                <Text>{subject["doctor"]?.phone}</Text>
              </SubjectsCell>
              <SubjectsCell {...actions}>
                <StatusSummary access={access} />
              </SubjectsCell>
              <SubjectsCell {...actions} isRight>
                {getPortalStyleDateTime(subject.updatedAt)}
              </SubjectsCell>
              <Td verticalAlign="middle" bg="white" cursor="initial">
                {access.owned && (
                  <SubjectActionsMenu
                    subject={access.subject}
                    shares={access.shares}
                    refetch={refetch}
                  />
                )}
              </Td>
            </SubjectsRow>
          )
        })}
      </SubjectsTable>

      <TableNavigation
        page={page}
        hasMore={hasMore}
        switchPage={(newPage) => router.push({ query: { page: newPage } })}
      />
    </>
  )
}

export const DistributorSubjectsLoader: FC = () => {
  return (
    <>
      <TableNavigation page={0} hasMore={false} switchPage={() => 0} />

      <SubjectsTable>
        {[...new Array(ITEMS_PER_PAGE)].map((_, i) => {
          return (
            <SubjectsRow key={i}>
              <SubjectsCell isLeft>
                <Skeleton height="20px" w="65%" />
                <Skeleton height="20px" w="55%" mt={2} />
              </SubjectsCell>
              <SubjectsCell>
                <Skeleton height="20px" w="70%" />
                <Skeleton height="20px" w="85%" mt={2} />
              </SubjectsCell>
              <SubjectsCell>
                <Skeleton height="20px" w="30%" />
              </SubjectsCell>
              <SubjectsCell isRight>
                <Skeleton height="20px" w="50%" />
              </SubjectsCell>

              <Td verticalAlign="middle" bg="white" cursor="initial"></Td>
            </SubjectsRow>
          )
        })}
      </SubjectsTable>

      <TableNavigation page={0} hasMore={false} switchPage={() => 0} />
    </>
  )
}
