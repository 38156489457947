import { useUserFlags } from "../../utils/misc/userFlags"
import WelcomePageDistributors from "./distributors/WelcomePageDistributors"
import WelcomePageStaff from "./WelcomePageStaff"
import WelcomePageStandard from "./WelcomePageStandard"

const WelcomePage = () => {
  const { IS_DISTRIBUTOR, IS_STAFF } = useUserFlags()

  if (IS_STAFF) {
    return <WelcomePageStaff />
  }

  if (IS_DISTRIBUTOR) {
    return <WelcomePageDistributors />
  }

  return <WelcomePageStandard />
}

export default WelcomePage
