import { Checkbox, CheckboxProps, FormControl, FormHelperText, Text } from "@chakra-ui/react"
import { FC } from "react"
import { useField } from "react-final-form"

import { FieldProps } from "./Field"

interface Props extends FieldProps, Omit<CheckboxProps, "name" | "children"> {
  placeholder?: string
}

const CheckboxField: FC<Props> = (props) => {
  const {
    name,
    helperText,
    isRequired,
    isReadOnly,
    defaultValue,
    isDisabled,
    children,
    ...inputProps
  } = props
  const { input } = useField(name, { type: "checkbox" })

  return (
    <FormControl>
      <Checkbox
        mt={8}
        {...input}
        {...inputProps}
        isChecked={input.checked}
        isDisabled={isDisabled}
        isRequired={isRequired}
      >
        {children}
        {isRequired && (
          <Text as="span" color="red.500">
            &nbsp;*
          </Text>
        )}
      </Checkbox>
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  )
}

export default CheckboxField
